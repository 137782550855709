import React from 'react';
import Layout from '../../components/Layout';
import Slideshow from '../../components/Slideshow';

const AboutPage = () => (
	<Layout title='About Us'>
		<div className='row mb-5 justify-content-between align-items-center'>
			<div className='col-md-5 px-3 order-1'>
				<h2 className='my-3 display-5 raleway-700'>About ASGSR</h2>
				<p>
					ASGSR is a non-profit 501 C(6) founded in 1984 to foster and promote research, education and
					professional development in the multidisciplinary fields of microgravity research. The society is
					composed of approximately 600 scientists, engineers and students who are doing research in space,
					primarily on the ISS and also research in academic and industry laboratories.
				</p>
				<h2 className='my-3 mt-lg-5 display-7 raleway-700'>The Students Chapter</h2>
				<p>
					The ASGSR student group is for students of all levels (K-12, undergraduate, graduate,etc) as well as
					recent graduates. We participate in a variety of education/outreach activities to help promote
					interest in space research, and provide a social forum for students interested in space research.
					One of our main functions is to help organize student events at the annual ASGSR meeting. At this
					meeting, students working on all aspects of gravitational research come together to talk about their
					research and network with professionals. For information on student board activities, you can read{' '}
					<a href='/INFO.pdf' target='_blank'>
						student board descriptions
					</a>
					.
				</p>
				<p>
					For more information, you can read{' '}
					<a href='/bylaws.pdf' target='_blank'>
						our bylaws
					</a>
					.
				</p>
			</div>
			<div className='my-3 col-md-5 order-0 order-md-2'>
				<Slideshow />
			</div>
		</div>
	</Layout>
);

export default AboutPage;
